import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let getMarkdownTextLoad = (uiBody:GetMarkdownTextUIRequestBody): Observable<GetMarkdownTextAPIRequestBody>=>{



  let apiBody = new GetMarkdownTextAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let getMarkdownTextSuccess = (apiBody:GetMarkdownTextAPIResponseBody):GetMarkdownTextUIResponseBody=>{
  let uiBody = new GetMarkdownTextUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class GetMarkdownTextUIRequestBody {
  constructor(params:Partial<GetMarkdownTextUIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  url:string
}

export class GetMarkdownTextAPIRequestBody {
  constructor(params:Partial<GetMarkdownTextAPIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<GetMarkdownTextUIRequestBody>
}

export class GetMarkdownTextUIResponseBody {
  constructor(params:Partial<GetMarkdownTextUIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }

}

export class GetMarkdownTextAPIResponseBody {
  constructor(params:Partial<GetMarkdownTextAPIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<GetMarkdownTextUIResponseBody>
}

