<div [class]="classPrefix('MainPod')">

  <input
  #numberSection
  [ngStyle]="numberSection.style"
  *ngFor="let numberSection of params.sections"
  (keyup)=captureInput()
  [class]="classPrefix('MainInput0')"
  [placeholder]="repeatString(numberSection.delimiter,numberSection.amount)"
  [attr.maxlength]="numberSection.amount"
  [value]="numberSection.value"
  pattern="[0-9]*"  />

</div>
