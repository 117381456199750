<div [class]="classPrefix('MainPod')">
  <ul [class]="classPrefix('Pod0')">
    <li [class]="classPrefix('Pod0Item0')" *ngFor="let row of params.rows">
      <ul [class]="classPrefix('Pod0Item1')" *ngFor="let section of row">
        <li
          [ngStyle]="cell.style"
          [class]="classPrefix('Pod0Item2') + ' ' +cell.class"
          *ngFor="let cell of section.value"
        >
          {{ cell.text | translate }}
        </li>
      </ul>
    </li>
  </ul>
</div>
