<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <h2>{{params.title.text | translate}}</h2>
    <wml-button-one [params]="params.actionBtn"></wml-button-one>
  </section>
  <section [class]="classPrefix('Pod1')">
    <p>{{params.desc.text | translate}}</p>
  </section>

</div>
