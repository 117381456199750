import { fromEvent, map } from "rxjs";

export let makeAFileAndDowlnload = (base64String,filename='export_data')=>{
  let binaryString = atob(base64String);
  let uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([uint8Array]);

  let downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename ;
  downloadLink.click();
}
export function getFileType(fileName: string): string | null {
  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex === -1) {
      // No file extension found
      return null;
  }

  const fileType = fileName.substring(dotIndex + 1);
  return fileType.toLowerCase();
}

export let readFileContent = (
  file: File,
  readPredicate:
    | 'readAsBinaryString'
    | 'readAsArrayBuffer'
    | 'readAsDataURL'
    | 'readAsText' = 'readAsBinaryString'
) => {
  let reader = new FileReader();
  reader[readPredicate](file);

  return fromEvent(reader as any, 'load').pipe(
    map(() => {
      let content
      if(readPredicate === "readAsBinaryString"){
        // @ts-ignore
        content = btoa(reader.result);
      }
      else{
        content = reader.result.toString();
      }

      return { content, file };
    })
  );
};
