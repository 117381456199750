<div #mainPod [class]="classPrefix('MainPod')">
  <div [class]="classPrefix('MainItem0')">
    <section [class]="classPrefix('Pod0')">
      <img   [src]=params.imgUrl.src [alt]="params.imgUrl.alt | translate" />
      <h3 (click)="openLink(params.resourceUrl)" [class]="classPrefix('Pod0Title0')">
        <a >{{params.title | translate}}</a>
      </h3>
    </section>
    <section *ngIf="params.summary.isPresent" [class]="classPrefix('Pod1')">
      <p>{{params.summary.text | translate}}</p>
    </section>
  </div>
  <div [class]="classPrefix('MainItem1')" *ngIf="params.type==='project'">

    <ng-container
    *ngFor="let resource of params.project.resources"
    >
      <a
      *ngIf="resource.isPresent"
      [href]="resource.href"
      [class]="classPrefix('MainImg0')"
      href="">

        <img

        [src]=resource.src [alt]="resource.alt | translate" />
      </a>
    </ng-container>


  </div>

</div>


