// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/angular-wml-components-base';


// misc

import { ENV } from '@env/environment';
import { StoreServiceItem, StoreServiceItemPrice } from '@shared/services/store/models';
import { WMLButtonParamsTypeEnum, WmlButtonZeroParams } from '@windmillcode/angular-wml-button-zero';
import { ColorOptionZeroParams } from '../color-option-zero/color-option-zero.component';
import { StoreService } from '@shared/services/store/store.service';



@Component({

  selector: 'panel-cart-zero',
  templateUrl: './panel-cart-zero.component.html',
  styleUrls: ['./panel-cart-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class PanelCartZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService,
    public storeService:StoreService
  ) { }

  classPrefix = generateClassPrefix('PanelCartZero')
  idPrefix = generateClassPrefix(ENV.idPrefix.panelCartZero)
  @Input('params') params: PanelCartZeroParams = new PanelCartZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  shoppingCartTotal = new StoreServiceItemPrice()
  removeItem = (index:number)=>{
    this.params.cart.splice(index,1)
    this.storeService.cartSubj.next("removeItem")

  }
  createRemoveItemBtn = (index:number)=>{
    return new WmlButtonZeroParams({
      type:WMLButtonParamsTypeEnum.SECONDARY,
      text:new WMLUIProperty({
        text:"PanelCart.removeItemBtn"
      }),
      button:new WMLUIProperty({
        click:()=>{this.removeItem(index)}
      })
    })
  }

  calculateShoppingCartTotal = ()=>{
    if(!this.params?.cart[0]){
      this.shoppingCartTotal.business =0
      return
    }

    let currency = this.params.cart[0].totalPrice.currency
    let business = 0
    Object.assign(
      this.shoppingCartTotal,
      {
        business,
        currency
      }
    )


    this.params.cart.forEach((item)=>{
      this.shoppingCartTotal.business += item.totalPrice.business

    })
  }

  listenForCartQuantity = ()=>{
    return this.storeService.cartSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.calculateShoppingCartTotal()
        this.cdref.detectChanges()
      })
    )

  }

  setupCheckoutBtnClick() {
    if (this.params?.checkoutBtn) {
      this.params.checkoutBtn.button.click = () => {
        this.storeService.buyNow();
        this.params.closePanel();
      };
    }
  }

  ngOnInit(): void {
    this.calculateShoppingCartTotal()
    this.listenForCartQuantity().subscribe()
    this.setupCheckoutBtnClick();

  }



  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}

export class PanelCartZeroItemParams extends StoreServiceItem {
  constructor(params: Partial<PanelCartZeroItemParams> = {}) {
    super()
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  color:ColorOptionZeroParams
  size:WMLUIProperty
  variant:{
    id:string
  }
}

export class PanelCartZeroParams {
  constructor(params:Partial<PanelCartZeroParams>={}){
    let origParams = Object.entries(params)
    .filter(([key,val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) })

  }

  cart:PanelCartZeroItemParams[] =[]
  closePanel:Function
  checkoutBtn=new WmlButtonZeroParams({
    button:new WMLUIProperty({

    }),
    text:new WMLUIProperty({
      text:"PanelCart.buyNowBtn"
    })
  })
}


