
import { transformObjectKeys } from "@core/utility/common-utils"
import { transformFromCamelCaseToSnakeCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let purchaseProductsLoad = (uiBody:PurchaseProductsUIRequestBody): Observable<PurchaseProductsAPIRequestBody>=>{


  let apiBody = new PurchaseProductsAPIRequestBody({
    data:transformObjectKeys(
      uiBody,transformFromCamelCaseToSnakeCase
    )
  })


  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let purchaseProductsSuccess = (apiBody:PurchaseProductsAPIResponseBody):void=>{
  window.location.href = apiBody.data.webUrl
}

export class PurchaseProductsUIRequestBody {
  constructor(params:Partial<PurchaseProductsUIRequestBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  items:{
    quantity:number
    variantId:string
  }[] =[]
  redirectUrl:string
  accessToken?:string
}

export class PurchaseProductsUIResponseBody {
  constructor(params:Partial<PurchaseProductsUIResponseBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
}

export class PurchaseProductsAPIRequestBody {
  constructor(params:Partial<PurchaseProductsAPIRequestBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  data:{
    items:{
      quantity:number
      variant_id:string
    }[]
  } ={
    items:[]
  }
}

export class PurchaseProductsAPIResponseBody {
  constructor(params:Partial<PurchaseProductsAPIResponseBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  data:{
    webUrl:string
  }
}

