import { Injectable } from '@angular/core';
import { ENV } from '@env/environment';
import { BaseService } from '@core/base/base.service';
import { WmlNotifyService } from '@windmillcode/angular-wml-notify';
import { WMLCustomComponent, WMLImage, WMLUIProperty } from '@windmillcode/angular-wml-components-base';
import { UtilityService } from '@core/utility/utility.service';
import { GenerateFieldParams, createWMLFormField } from '@core/utility/form-utils';
import { ColorOptionZeroParams, ColorOptionZeroComponent } from '@shared/components/color-option-zero/color-option-zero.component';
import { WMLOptionItemParams, WMLOptionsParams } from '@windmillcode/angular-wml-options';
import { DatePickerZeroComponent, DatePickerZeroParams } from '@shared/components/date-picker-zero/date-picker-zero.component';
import { CardExpiryInputZeroComponent, CardExpiryInputZeroParams } from '@shared/components/card-expiry-input-zero/card-expiry-input-zero.component';
import { CardNumberInputZeroComponent, CardNumberInputZeroParams } from '@shared/components/card-number-input-zero/card-number-input-zero.component';
import { VerifiedZeroComponent, VerifiedZeroParams } from '@shared/components/verified-zero/verified-zero.component';

@Injectable({
  providedIn: 'root'
})
export class SpecificService  {
  constructor(
    public utilService:UtilityService,
    public wmlNotifyService:WmlNotifyService,
    public baseService:BaseService
  ){}
  getLogoImg = ()=>new WMLImage({
    src:"assets/media/app/logo-no-bg.png",
    alt:"global.logoImgAlt",
    click:()=>{
      this.utilService.router.navigateByUrl(ENV.nav.urls.home)
    }
  })
  logoImg = this.getLogoImg()

  _manageWebStorage(webStorage:Storage,thisWebStorage:any,predicate:Function) {
    let myWebStorage = webStorage.getItem(ENV.classPrefix.app);
    myWebStorage = JSON.parse(myWebStorage);
    Object.assign(thisWebStorage, myWebStorage);
    predicate()
    webStorage.setItem(ENV.classPrefix.app, JSON.stringify(thisWebStorage))
  }

  createColorZeroFormField = (
    params: GenerateFieldParams<WMLOptionsParams>&
    {colors:Array<{color:string,value:string}>}
  ) => {
    let { fieldCustomParams,colors,fieldParentForm,fieldFormControlName } = params;
    let wmlField = this.baseService.createOptionsFormField(params);
    wmlField.field.custom.params = new WMLOptionsParams({
      limit:1,
      formArray:fieldParentForm.get(fieldFormControlName),
      options:colors.map((item)=>{
        return new WMLOptionItemParams({
          value:item.value,
          toggleClass:"ColorOptionZeroMainPod0",
          customCpnt:new WMLCustomComponent({
            cpnt:ColorOptionZeroComponent,
            params:new ColorOptionZeroParams({
              color:new WMLUIProperty({
                style:{background:item.color}
              })
            })
          })
        })
      }),
      ...fieldCustomParams,
      // wmlField,
    });

    return wmlField;
  };

  createField=(params =new GenerateFieldParams<any>(),cpnt:WMLCustomComponent["cpnt"])=>{
    let {
      fieldCustomParams
    } = params
    fieldCustomParams = fieldCustomParams
    let wmlField = createWMLFormField(params)
    wmlField.field.custom.cpnt = cpnt
    wmlField.field.custom.params  =fieldCustomParams

    return wmlField
  }

  createDatePickerField=(params =new GenerateFieldParams())=>{
    params.fieldCustomParams ??= new DatePickerZeroParams()
    return this.createField(params,DatePickerZeroComponent)
  }

  createCardNumberInputField=(params =new GenerateFieldParams())=>{
    params.fieldCustomParams ??= new CardNumberInputZeroParams()
    return this.createField(params,CardNumberInputZeroComponent)
  }

  createCardExpiryField=(params =new GenerateFieldParams())=>{
    params.fieldCustomParams ??= new CardExpiryInputZeroParams()
    return this.createField(params,CardExpiryInputZeroComponent)
  }

  createVerifiedField=(params =new GenerateFieldParams())=>{
    params.fieldCustomParams ??= new VerifiedZeroParams()
    return this.createField(params,VerifiedZeroComponent)
  }




}
