import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WmlInfiniteDropdownOption } from "@windmillcode/angular-wml-infinite-dropdown"
import { Observable, of, take } from "rxjs"

export let updateAdressesLoad = (uiBody:UpdateAdressesUIRequestBody): Observable<UpdateAdressesAPIRequestBody>=>{

  let apiBody = new UpdateAdressesAPIRequestBody({data:{
    access_token:uiBody.accessToken,
    addresses:uiBody.addresses.map((val)=>{
      return {
        address1:val.streetAdr,
        address2:val.secondaryAdr,
        city:val.city,
        countryCode:val.country?.value,
        provinceCode:val.state?.value,
        zip:val.zipcode
      }
    })
  }})
  console.log(apiBody)
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let updateAdressesSuccess = (apiBody:UpdateAdressesAPIResponseBody):UpdateAdressesUIResponseBody=>{
  let uiBody = new UpdateAdressesUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class UpdateAdressesUIRequestBody {
  constructor(params:Partial<UpdateAdressesUIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  accessToken:string
  addresses:Array<{
    streetAdr:string
    secondaryAdr:string
    city:string
    country:WmlInfiniteDropdownOption
    state:WmlInfiniteDropdownOption
    zipcode:string
  }>


}

export class UpdateAdressesAPIRequestBody {
  constructor(params:Partial<UpdateAdressesAPIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:{
    access_token:string
    addresses:Array<{
      address1:string
      address2:string
      city:string
      countryCode:string
      provinceCode:string
      zip:string
    }>
  }
}

export class UpdateAdressesUIResponseBody {
  constructor(params:Partial<UpdateAdressesUIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }

}

export class UpdateAdressesAPIResponseBody {
  constructor(params:Partial<UpdateAdressesAPIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<UpdateAdressesUIResponseBody>
}

