
import { WmlTableZeroSampleCardParams } from '@windmillcode/angular-wml-table-zero';
import { WMLImage } from '@windmillcode/angular-wml-components-base';
import { ListProductsForProductsDataAPIResponseBody } from './listProductsForProducts';
import { RatingStarsParams } from '@shared/components/rating-stars/rating-stars.component';
import { roundToXDecimals } from '@core/utility/number-utils';

export class StoreServiceItem {
  constructor(params:Partial<StoreServiceItem>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }

  id:string
  imgs :WMLImage[]=[]
  displayImg =this.imgs[0]
  title:string
  subtitle:string
  quantity:number

  price:StoreServiceItemPrice

  rating = new RatingStarsParams({
    rating: 4,
  });
  get totalPrice(){
    let value = parseFloat((this.price.business * this.quantity).toFixed(2));


    let result = new StoreServiceItemPrice({

    })
    Object.assign(result, {
      business:value,
      currency:this.price.currency,
      serverDisplay:this.price.currency+value
    })
    return result
  }
}

export class StoreServiceItemPrice  {
  constructor(params:Partial<StoreServiceItemPrice>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
    this.business = parseFloat(this.business)
  }
  business:any= 0
  currency= "$"
  serverDisplay:null
  get display(){
    return this.serverDisplay ?? this.currency+ roundToXDecimals(this.business,2)
  }

}

export class StoreServiceTableCardItem extends WmlTableZeroSampleCardParams{
  constructor(params:Partial<StoreServiceTableCardItem>={}){
    super()
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  productDetail:ListProductsForProductsDataAPIResponseBody
}
