export class DateTimeZero  {
  constructor(params: Partial<DateTimeZero > = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  years = 0;
  months = 0;
  days = 0;
  hours = 0;
  minutes =0;
  seconds = 0;
  milliseconds = 0;
}
export function calculateTimeInFuture(duration: DateTimeZero): Date {
  const now = new Date();
  now.setFullYear(now.getFullYear() + duration.years);
  now.setMonth(now.getMonth() + duration.months);
  now.setDate(now.getDate() + duration.days);
  now.setHours(now.getHours() + duration.hours);
  now.setMinutes(now.getMinutes() + duration.minutes);
  now.setSeconds(now.getSeconds() + duration.seconds);
  now.setMilliseconds(now.getMilliseconds() + duration.milliseconds);

  return now;
}
