
import { SITE_OFFLINE_ENUM } from '@core/utility/common-utils';
import { makeTitleCase } from '@core/utility/string-utils';

import { WMLEndpoint } from '@windmillcode/angular-wml-components-base';

export let environment = {
  production: false
}



export class DevEnv {

  // this must be a union type for import purposes
  type:"DEV" | "DOCKER_DEV" |"PREVIEW" | "PROD" | "TEST"= "DEV"
  endpointMsgCodes = {
    success:'OK',
    error:'ERROR',
    respViaSocketio:"RESPONSE_VIA_SOCKETIO"
  }

  errorCodes = {
  }

  app=(()=>{
    let app ={
      backendHealthCheck:() =>this.backendDomain0 + "/healthz/",
      siteOffline:SITE_OFFLINE_ENUM.FALSE,
      freeTrialEndsAfter:Infinity,
      originalConsoleObj :[]
    }
    return app
  })()


  backendServerName0 = "example.com:10071"
  get backendDomain0(){
    return "https://"+this.backendServerName0
  }
  frontendDomain0 ="https://example.com:10001"


  classPrefix= {
    app:"App",
    products:"Products"
  }

  idPrefix:{[k:string]:string} ={
    nav:"Nav_",
    sectionTwo:"SectionTwo_",
    productsZero:"ProductsZero_",
    productDetailZero:"ProductDetailZero_",
    colorOptionZero:"ColorOptionZero_",
    panelCartZero:"PanelCartZero_",
    panelCartContainerZero:"PanelCartContainerZero_",
    checkoutZero:"CheckoutZero_",
    datePickerZero:"DatePickerZero_",
    cardExpiryInputZero:"CardExpiryInputZero_",
    cardNumberInputZero:"CardNumberInputZero_",
    cardCvvInputZero:"CardCvvInputZero_",
    accountProfileZero:"AccountProfileZero_",
    verifiedZero:"VerifiedZero_",
    labViewerZero:"LabViewerZero_",
    imageUploadZero:"ImageUploadZero_",
    accountProjects:"AccountProjects_"
  }

  nav = (()=>{
    let nav = {
      urls:{
        home:"/",
        homeAlt:"/home",
        about:"/about",
        contact:"/contact",
        blog:"/blog",
        onlineEvents:"/events/online",
        inPersonEvents:"/events/in-person",

        initialURL:"",
        siteOffline:"/site-offline",
        privacyPolicy:"/legal/privacy-policy",
        termsAndConditions:"/legal/terms-and-conditions",
        signUp:"/auth/sign-up",
        signIn:"/auth/sign-in",
        labs:"/labs",
        account:"/account",
        accountProfile:"/account/profile",
        accountBilling:"/account/billing",
        accountManageYourData:"/account/manage-your-data",
        plans:"/plans",
        projectsZero:"/projects",
        productsZero:"/store/products",
        productDetailZero:"/store/product-detail",
        checkoutZero:"/store/checkout",
        accountProjects:"/account/projects"

      },
    }

    let idPrefixes = Object.entries(nav.urls).map(([key,val])=>{
      return [key,makeTitleCase(key)+"_"]
    })
    this.idPrefix ={
      ...Object.fromEntries(idPrefixes),
      ...this.idPrefix
    }
    return nav
  })()


  firebase=(()=>{
    let storageImageURL =
      'http://127.0.0.1:9199/v0/b/windmillcodesite.appspot.com/o/';
    let firebase = {
      config:{
        apiKey: "AIzaSyAsoFBcz3VnCmJqyefv5R2VAhuswpgIINg",
        authDomain: "127.0.0.1",
        projectId: "windmillcodesite",
        messagingSenderId: "159461117267",
        appId: "1:159461117267:web:74b2b8198f5a4e96fc65a6",
        measurementId: "G-L9NS74L8R1",
      },
      storageImageURL,
      getImageUrlFromStorage: (resourcePath?: string) => {
        return `${firebase.storageImageURL}${encodeURIComponent(
          resourcePath
        )}?alt=media`;
      },
    }
    return firebase
  })()
  imageUploadZero={

    mainForm :{
    	avatarUrlFormControlName:"avatarUrl"
    }

  }




  accountProfileZero={
    mainForm :{
    	displayNameFormControlName:"displayName",
    	emailFormControlName:"email",
    	emailVerifiedFormControlName:"emailVerified",
    	phoneNumberFormControlName:"phoneNumber"
    },
    notifyForm :{
      emailMarketingConsentFormControlName:"emailMarketingConsent",
      smsMarketingConsentFormControlName:"smsMarketingConsent"
    }
  }

  productDetailZero={

    mainForm :{
    	quantityFormControlName:"quantity",
    	colorFormControlName:"color",
    	sizeFormControlName:"size"
    }

  }

  contactUsForm = {
    mainForm:{
      nameFormControlName:"name",
      companyFormControlName:"company",
      emailFormControlName:"email",
      phoneFormControlName:"phone",
      descFormControlName:"desc"
    }
  }

  productsDetailMain = {
    mainForm:{
      titleFormControlName:"title",
      imgFormControlName:"img",
      idFormControlName:"id",
      quantityFormControlName:"quantity",
      colorFormControlName:"color",
      sizeFormControlName:"size",
    }
  }

  accountBillingZero = {
    addressForm:{
      streetAdrFormControlName:"streetAdr",
      secondaryAdrFormControlName:"secondaryAdr",
      cityFormControlName:"city",
      stateFormControlName:"state",
      zipcodeFormControlName:"zipcode",
      countryFormControlName:"country",
    },

    creditCardForm :{
      cardNumberFormControlName:"cardNumber",
      expiryDateFormControlName:"expiryDate",
      securityCodeFormControlName:"securityCode"
    }

  }

  accountsService = {
    listUsers:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/users/list",
    }),
    deleteUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/users/delete",
    }),
    addCardToUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/cards/create",
    }),
    listCards:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/cards/list",
    }),
    deleteCards:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/cards/delete",
    }),
    getSquareCustomerIDVIAFirebaseID:new WMLEndpoint({
      url:()=>this.backendDomain0 + "/accounts/get_square_customer_id_via_firebase_access_token"
    }),
    exportUsers:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/users/export",
    }),
    getCardServerSessionId:new WMLEndpoint({
      url:()=> "https://elb.deposit.shopifycs.com/sessions",
    }),
    updateAdresses:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/addresses/update",
    }),
    updateUser:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/accounts/users/update",
      })
  }

  blogService = {
    listBlogs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/blogs/list",
    })
  }

  storeService= {
    listProducts:new WMLEndpoint({
      url:()=>this.backendDomain0 + "/store/products/list"
    }),
    purchaseProducts:new WMLEndpoint({
      url:()=>this.backendDomain0 + "/store/products/purchase"
    }),


  }
  eventService ={
    getOnlineEvents:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/events/online",
    }),
    getInPersonEvents:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/events/in-person",
    }),
  }

  contactService = {
    submitNewClient:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/contact/submit-new-client",
    })
  }
  labsService = {
    listLabs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/labs/list",
    }),
    getMarkdownText:new WMLEndpoint({
    })
  }

  constructor(){
    this.app.originalConsoleObj = Object.entries(console)
    // traverseClassAndRemoveAutomationForProduction(this)
  }
}

export let ENV =   new DevEnv()


