import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel, WMLDeepPartial } from '@windmillcode/angular-wml-components-base';
import { Observable, Subject, concatMap, defer, iif, map, of, tap } from 'rxjs';
import { RecursiveSnakeCaseType } from './string-utils';
import localforage from 'localforage';
import { DateTimeZero, calculateTimeInFuture } from './date-utils';



export  class WMLDataSourceSinkResponseBody {
  constructor(params: Partial<WMLDataSourceSinkResponseBody> = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<WMLAPIPaginationResponseModel>
}

export type WMLDataSourceRequestModifierPredicate<R = WMLAPIPaginationRequestModel> = (req: R, self: WMLDataSource<R>) => R;

export let afterCursorRequestModifierPredicate:WMLDataSourceRequestModifierPredicate = (req,self )=>{

  req.cursor ={}
  if (self.currentSource.data.length !== 0) {
    req.cursor = {
      value: self.currentSource.data.at(-1).cursor,
      order: self.currentSource.data.length - 1
    };
  }
  return req
}

export class WMLDataSourceCurrentSource {
  constructor(params: Partial<WMLDataSourceCurrentSource> = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  totalItems = null
  totalPages = null
  data = []
}

export class WMLDataSourceWebStorageEntity {
  constructor(params: Partial<WMLDataSourceWebStorageEntity> = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  expiryDate:string
  data:WMLDataSource["sources"]

}

function WMLDataSourceUpdateSourcesObjUpdatePredicate(a,b){
  return {
    ...a,
    ...b
  }
}
export  class WMLDataSourceUpdateSourcesObj {
  constructor(params: WMLDeepPartial<WMLDataSourceUpdateSourcesObj> = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
    this.configurations.forEach((config)=>{
      config.updatePredicate ??=WMLDataSourceUpdateSourcesObjUpdatePredicate
    })
  }
  row:any
  id:string
  configurations:Array<{
    key:string
    position:number
    action:"update" | "delete" | "insert" |"replace"
    updatePredicate:Function
  }>
}


export class WMLDataSource<R = WMLAPIPaginationRequestModel> {
  constructor(params: Partial<WMLDataSource<R>> = {}) {
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }


  currentSource :WMLDataSourceCurrentSource;
  sources ={}
  webStorageObj:{
    key:string,
    expiry?:DateTimeZero
  }

  transformationPredicate= (val:any) => val;
  get sucessPredicate(){
    return this.transformationPredicate
  }
  getFromSink: (req:R)=>Observable<WMLDataSourceSinkResponseBody> = (val) => new Subject<any>();

  updateSources=(data:WMLDataSourceUpdateSourcesObj[])=>{

    data.forEach((updateObj)=>{
      updateObj.configurations.forEach((config)=>{
        let targetSource = this.sources?.[config.key]
        if (!targetSource) return;
        if(config.action ==="insert"){
          if ([null,undefined].includes(config.position)){
            targetSource.data.push(updateObj.row)
            targetSource.totalItems += 1
            targetSource.totalPages +=1
          }
        }
        if(config.action ==="update"){
          let index = targetSource.data.findIndex((val)=>val.id === updateObj.id)
          targetSource.data[index] = config.updatePredicate(targetSource.data[index],updateObj.row)
        }
        if(config.action ==="replace"){
          let index = targetSource.data.findIndex((val)=>val.id === updateObj.id)
          targetSource.data[index] = updateObj.row
        }
        if(config.action ==="delete"){
          let index = targetSource.data.findIndex((val)=>val.id === updateObj.id)
          targetSource.data.splice(index,1)
          targetSource.totalItems -= 1
          targetSource.totalPages -=1
        }
      })
    })
    return this.syncWithBrowserStorage()
    .pipe(
      tap(()=>this.updateSourcesEvent.next(data))
    )

  }
  updateSourcesEvent = new Subject<WMLDataSourceUpdateSourcesObj[]>()

  updateCurrentSource = (req)=>{

    let key = JSON.stringify([req.sort,req.filter])
    this.sources[key] ??=new WMLDataSourceCurrentSource()
    this.currentSource = this.sources[key]
  }

  invalidateCache = ()=>{
    this.sources = {}
    this.currentSource =null
    return defer(async()=>{
      await localforage.removeItem(this.webStorageObj?.key)
    })
  }

  checkForPersistedData() {
    return defer(async ()=>{
      if(this.webStorageObj){
        let {key} = this.webStorageObj
        let data:WMLDataSourceWebStorageEntity = await localforage.getItem(key);
        if(!data){
          return
        }
        if(new Date(data.expiryDate).getTime() > new Date().getTime()){
          this.sources= Object.fromEntries(Object.entries(data.data).map(([key,val])=>{
            return [key,new WMLDataSourceCurrentSource(val)]
          }))
        }
        else{
          await localforage.removeItem(key)
        }

      }
    })
  }

  syncWithBrowserStorage() {
    return defer(async ()=>{
      if(this.webStorageObj){
        let {key,expiry} = this.webStorageObj

        if(expiry){
          let expiryDate = calculateTimeInFuture(expiry)
          await localforage.setItem(key,{
            expiryDate,
            data:this.sources
          })
        }

      }
    })

  }

  queryDataFromSource = (req) => {

      return this.checkForPersistedData()
      .pipe(
        map(()=>{
          this.updateCurrentSource(req)
          let {startIndex,endIndex } = req.getIndexInfo()
          if(req.pageSize === Infinity){
            startIndex = 0
            endIndex = Infinity
          }
          if(this.currentSource.totalItems !==null){
            endIndex = endIndex > this.currentSource.totalItems ? this.currentSource.totalItems :endIndex
          }
          let expectedRange = endIndex-startIndex
          let haveAllItemsInRange= Array(expectedRange)
          .fill(null)
          .every((nullVal,index0)=>{
            let targetIndex = index0+startIndex

            return this.currentSource.data[targetIndex]
          })
          return {
            haveAllItemsInRange,startIndex,endIndex
          }
        }),
        concatMap(({haveAllItemsInRange,startIndex,endIndex})=>{
          return iif(
            ()=>haveAllItemsInRange,
            (()=>{
              let newResp = new WMLAPIPaginationResponseModel({
                pageNum:req.pageNum,
                data:this.currentSource.data.slice(startIndex,endIndex),
                // done for filter and sort logic and other instance where exact page values are unknwon
              }).calculateCurrentState(this.currentSource.totalPages,this.currentSource.totalItems,req.pageSize)

              return of(new WMLDataSourceSinkResponseBody({
                // @ts-ignore
                data:{
                  page_num:newResp.pageNum,
                  page_size:newResp.pageSize,
                  data:newResp.data,
                  total_items:newResp.totalItems,
                  total_pages:newResp.totalPages
                }
              }))
            })(),
            (()=>{
              // @ts-ignore
              req= this.requestModifierPredicate(req,this);
              return this.getFromSink(req)
              .pipe(
                tap((res)=>{
                  let startIndex = res.data.metadata.start_order_value
                  if(this.currentSource.data.length < startIndex){
                    this.currentSource.data = this.currentSource.data.concat(
                      Array(startIndex-this.currentSource.data.length).fill(null)
                    )
                  }
                  this.currentSource.data.splice(
                    startIndex,
                    0,...res.data.data
                  )


                  res.data.data = this.currentSource.data.slice(startIndex,endIndex)

                  this.currentSource.totalItems = res.data.total_items
                  this.currentSource.totalPages = res.data.total_pages
                }),
                concatMap((res)=>{
                  return this.syncWithBrowserStorage()
                  .pipe(
                    map(()=>res)
                  )

                })
              )
            })()
          )
        }),
        map(this.transformationPredicate)
      )




    };

  requestModifierPredicate:WMLDataSourceRequestModifierPredicate  =(req,self)=> {
    return req
  }

}



