<div [class]="classPrefix('MainPod')">

  <div class="exp-wrapper"  >
    <input #month
    (input)="onMonthInput($event)"
    (keypress)="onInputKeyPress($event)"
    (keyup)="captureInput()"
    autocomplete="off" class="exp"  maxlength="2" pattern="[0-9]*" inputmode="numerical" placeholder="MM" type="text"  />
    <p [class]="classPrefix('MainText0')">/</p>
    <input
    #year
    (keydown)="onYearInputKeydown($event)"
    (keypress)="onInputKeyPress($event)"
    (keyup)="captureInput()"
    autocomplete="off" class="exp"  maxlength="2" pattern="[0-9]*" inputmode="numerical" placeholder="YY" type="text"  />
  </div>
</div>
