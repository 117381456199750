import { WMLAPIPaginationResponseModel, WMLCustomComponent, WMLImage, WMLUIProperty } from "@windmillcode/angular-wml-components-base";
import { WmlTableZeroItemParams, WmlTableZeroSampleCardComponent, WmlTableZeroSampleCardParams, WmlTableZeroSampleCardPriceParams } from "@windmillcode/angular-wml-table-zero";
import { StoreServiceTableCardItem } from "./models";
import { transformObjectKeys } from "@core/utility/common-utils";
import { RecursiveSnakeCaseType, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils";
import { ListProductsUIResponseBody } from "./listProducts";
import { getIdValueFromShopifyProductId } from "@core/utility/specific-utils/shopify-utils";





export let listProductsForProductsSuccess = (
  navToProductDetailsPage= (val:any)=>{},
)=>(apiBody:ListProductsForProductsAPIResponseBody):ListProductsUIResponseBody=>{


  let apiData = transformObjectKeys(apiBody.data,transformFromSnakeCaseToCamelCase)
  let uiBody = new WMLAPIPaginationResponseModel<WmlTableZeroItemParams>(apiData)
  uiBody.data =apiBody.data.data.map((row)=>{


    let tableRow = new WmlTableZeroItemParams({
      businessData:{
        price:row.price.business,
        title:row.title,
      },
    })
    tableRow.customCpnts[0].custom =new WMLCustomComponent({
      cpnt:WmlTableZeroSampleCardComponent,
      params:new StoreServiceTableCardItem({
        title : new WMLUIProperty({
          text:row.title
        }),
        subtitle : new WMLUIProperty({
          text:row.subtitle
        }),
        price : new WmlTableZeroSampleCardPriceParams({
          text:row.price.display
        }),
        img:new WMLImage({
          src:row.image_urls[0].src,
          alt:row.image_urls[0].alt
        }),
        metadata:row
      })
    })
    let cardParams:WmlTableZeroSampleCardParams = tableRow.customCpnts[0].custom.params

    cardParams.container.click = ()=>{
      navToProductDetailsPage(getIdValueFromShopifyProductId(row.id))
    }
    return tableRow
  })

  return uiBody

}



export class ListProductsForProductsAPIResponseBody {
  constructor(params:Partial<ListProductsForProductsAPIResponseBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  data:RecursiveSnakeCaseType<WMLAPIPaginationResponseModel<ListProductsForProductsDataAPIResponseBody>>
}

export class ListProductsForProductsDataAPIResponseBody {
  constructor(params:Partial<ListProductsForProductsDataAPIResponseBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }

  id: string;
  imageUrls: {
    src:string,
    alt:string
  }[];
  options:{
    key: {
      id: string;
      name: string;
    }[];
    values: {
      [id:string]: {
        id: string;
        name: string;
      }[];
    };
  }
  price: {
    business: number;
    currency: string;
    display: string;
  };
  subtitle: string;
  title: string;
  variations:{
    id: string;
    option_ids: {
      key: string;
      value: string;
    }[];
  }[]


}
