// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';

// wml components
import { WmlComponentsModule } from './wml-components/wml-components.module';

// i18n
import {  TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { OverlayLoadingComponent } from './components/overlay-loading/overlay-loading.component';



// misc
import { SampleCpntComponent } from './components/sample-cpnt/sample-cpnt.component';

import { StepperComponent } from './components/stepper/stepper.component';

import { RatingCardComponent } from './components/rating-card/rating-card.component';
import { RatingCarouselComponent } from './components/rating-carousel/rating-carousel.component';
import { NavZeroComponent } from './components/nav-zero/nav-zero.component';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { FooterZeroComponent } from './components/footer-zero/footer-zero.component';
import { SectionZeroComponent } from './components/section-zero/section-zero.component';
import { SectionOneComponent } from './components/section-one/section-one.component';
import { CardZeroComponent } from './components/card-zero/card-zero.component';
import { EventsPanelItemComponent } from './components/events-panel-item/events-panel-item.component';
import { LogoDisplayZeroComponent } from './components/logo-display-zero/logo-display-zero.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { CardOneComponent } from './components/card-one/card-one.component';
import { CarouselZeroComponent } from './components/carousel-zero/carousel-zero.component';
import { CardTwoComponent } from './components/card-two/card-two.component';
import { ConfirmDialogZeroComponent } from './components/confirm-dialog-zero/confirm-dialog-zero.component';
import { DashboardCtrlZeroComponent } from './components/dashboard-ctrl-zero/dashboard-ctrl-zero.component';
import { SectionTwoComponent } from './components/section-two/section-two.component';
import { ColorOptionZeroComponent } from './components/color-option-zero/color-option-zero.component';
import { PanelCartZeroComponent } from './components/panel-cart-zero/panel-cart-zero.component';
import { PanelCartContainerZeroComponent } from './components/panel-cart-container-zero/panel-cart-container-zero.component';
import { DatePickerZeroComponent } from './components/date-picker-zero/date-picker-zero.component';
import { CardExpiryInputZeroComponent } from './components/card-expiry-input-zero/card-expiry-input-zero.component';
import { CardNumberInputZeroComponent } from './components/card-number-input-zero/card-number-input-zero.component';
import { ReactiveFormsModule } from '@angular/forms';
import { VerifiedZeroComponent } from './components/verified-zero/verified-zero.component';
import { LabViewerZeroComponent } from './components/lab-viewer-zero/lab-viewer-zero.component';
import { ImageUploadZeroComponent } from './components/image-upload-zero/image-upload-zero.component';
import { NgHcaptchaModule } from 'ng-hcaptcha';


let components = [
  SampleCpntComponent,
  OverlayLoadingComponent,

  StepperComponent,
  RatingCarouselComponent,
  RatingCardComponent,
  RatingStarsComponent,
  NavZeroComponent,

  ContactUsFormComponent,
  FooterZeroComponent,
  SectionZeroComponent,
  SectionOneComponent,
  CardZeroComponent,
  ColorOptionZeroComponent,
  CardOneComponent,
  CarouselZeroComponent,
  CardTwoComponent,
  ConfirmDialogZeroComponent,
  DashboardCtrlZeroComponent,
  SectionTwoComponent,
  ColorOptionZeroComponent,
  PanelCartZeroComponent,
  PanelCartContainerZeroComponent,
  DatePickerZeroComponent,
  CardExpiryInputZeroComponent,
  CardNumberInputZeroComponent,
  VerifiedZeroComponent,
  LabViewerZeroComponent,
  ImageUploadZeroComponent,
]


let modules = [
  TranslateModule,
  CommonModule,
  WmlComponentsModule,
  ReactiveFormsModule,
  RouterModule,
]
@NgModule({
  imports:[

    ...modules,
    MarkdownModule.forRoot(),
    NgHcaptchaModule.forRoot({
      siteKey: 'ab64af49-93b4-4ac7-8a7e-77a14ef6086b'
    }),

  ],
  exports: [
    ...components,
    ...modules,
  ],
  providers:[
    {provide:TranslatePipe,useExisting:TranslatePipe}
  ],
  declarations: [
    ...components,

    CardZeroComponent,
    EventsPanelItemComponent,
    LogoDisplayZeroComponent,


  ]
})
export class SharedModule { }
