<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <h2 [class]="classPrefix('MainTitle0')">{{params.title.text | translate}}</h2>
    <wml-button-one
    *ngIf="params.actionBtn.isPresent"
    [params]="params.actionBtn"></wml-button-one>
  </section>
  <section [class]="classPrefix('Pod1')">
    <ng-content></ng-content>
  </section>
</div>
