export let getIdValueFromShopifyProductId =(id:string)=>{
  return id.split("Product/")[1]
}
export let getIdValueFromShopifyProductVariantId =(id:string)=>{
  return id.split("ProductVariant/")[1]
}

export let createShopifyProductId =(id:string)=>{
  return "gid://shopify/Product/"+id
}
