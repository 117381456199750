<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')">
      <h2
        [ngStyle]="title.style"
        [class]="title.class"
        (animationend)="title.animationEnd($event)"
      >{{params.title | translate}}</h2>
      <wml-button-one
      [class]="classPrefix('Pod0Btn0')"
      [params]="toggleREADMEBtn"></wml-button-one>
      <i (click)="closeViewer()"
      [class]="classPrefix('Pod0Icon0') + 'fa-solid fa-xmark'"></i>
    </div>

  </section>
  <section [class]="classPrefix('Pod1')">

    <div
      [ngStyle]="video.style"
      (animationend)="video.animationEnd($event)"
      [class]="video.class">
        <iframe
        *ngIf="videoUrl"
        [class]="classPrefix('Pod1Media0')"
        [title]="params.title"
        [src]="videoUrl"
        ></iframe>

        <blockquote
        *ngIf="trelloBoardUrl"
        [class]="classPrefix('Pod1Media0') + ' trello-board-compact'">
          <a [href]="trelloBoardUrl">Trello Board</a>
        </blockquote>
    </div>
    <div
      [ngStyle]="readme.style"
      (animationend)="readme.animationEnd($event)"
      [class]="readme.class">
      <markdown
        [class]="classPrefix('Pod1Media1')"
        [data]="markdownData"
      >

      </markdown>
    </div>
  </section>
</div>


