<footer [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item2')">
      <div [class]="classPrefix('Pod0Item0')">
        <logo-display-zero (click)="baseService.navToHome()" [class]="classPrefix('Pod0Item1')">
        </logo-display-zero>
        <p [class]="classPrefix('Pod0Text0')">{{"FooterZero.logoSection.text" | translate}}</p>
      </div>
      <section-zero [params]="quickLinkParams"></section-zero>
      <section-zero [params]="languageParams"></section-zero>
      <div [class]="classPrefix('Pod0Item3')">
        <h3>{{"FooterZero.socialMedia.title" | translate}}</h3>
        <div [class]="classPrefix('Pod0Item4')">
          <img
          (click)="img.click($event)"
          [class]="classPrefix('Pod0Img1')"
          *ngFor="let img of socialMediaImgs" [src]=img.src [alt]=img.alt />
        </div>
      </div>
      <section-zero [params]="legalParams"></section-zero>
    </div>

  </section>
  <section [class]="classPrefix('Pod1')">
    <p>&#64;{{fullYear}}{{"FooterZero.allRights" | translate}}</p>
  </section>
</footer>
