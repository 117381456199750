<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <h2 (click)="baseService.navToHome()"  [class]="classPrefix('Pod0Title0')">{{"NavZero.title" | translate }}</h2>
    <div [class]="classPrefix('Pod0Item0')">

      <wml-button-one [params]="donateBtn"></wml-button-one>
      <div [class]="classPrefix('Pod0Item1')" *ngIf="ENV.type !== 'PROD'">
        <wml-button-one   *ngIf="signUpBtn.isPresent"  [params]="signUpBtn"></wml-button-one>
        <wml-button-one  *ngIf="loginBtn.isPresent"  [params]="loginBtn"></wml-button-one>
        <wml-button-one  *ngIf="profileBtn.isPresent"  [params]="profileBtn"></wml-button-one>
        <wml-button-one  *ngIf="logoutBtn.isPresent"  [params]="logoutBtn"></wml-button-one>
      </div>


      <wml-toggle-zero [class]="classPrefix('Pod0Item2')" [params]="darkModeToggle"></wml-toggle-zero>

      <i
        (click)="navService.openMobileNav()"
        [class]="classPrefix('Pod0Icon0') + ' fa-solid fa-bars'">
      </i>
    </div>

  </section>
  <section [class]="classPrefix('Pod1')">

    <div [class]="classPrefix('Pod1Item0')">
      <wml-infinite-dropdown
      *ngFor="let dropdown of navService.desktopDropdowns"
      [params]="dropdown"
      ></wml-infinite-dropdown>
    </div>
  </section>
</div>
