// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding   } from '@angular/core';

// services

import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';


// rxjs
import { Subject } from 'rxjs';

// misc

import { ENV } from '@env/environment';
import { WMLImage, WMLRoute, generateClassPrefix } from '@windmillcode/angular-wml-components-base';
import { SectionZeroParams } from '../section-zero/section-zero.component';
import enTranslations from "src/assets/i18n/en.json";
import { documentQuerySelector } from '@core/utility/common-utils';


@Component({
  selector: 'footer-zero',
  templateUrl: './footer-zero.component.html',
  styleUrls: ['./footer-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class FooterZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('FooterZero')
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  ngUnsub= new Subject<void>()
  fullYear =  new Date().getFullYear();

  quickLinkParams = (()=>{
    let quickLinkParams = new SectionZeroParams({
      title:"FooterZero.quickLinks.title",
      items:enTranslations.FooterZero.quickLinks.items.map((item,index0)=>{
        return new WMLRoute({
          text:"FooterZero.quickLinks.items."+index0
        })
      })
    })

    let  [donate,about,pricing,store,contact] =quickLinkParams.items;
    [about,contact,store,pricing].forEach((link,index0)=>{
      let route = ["about","contact","productsZero","plans"][index0]
      link.click = ()=>{
        this.utilService.router.navigateByUrl(ENV.nav.urls[route])
        documentQuerySelector(".DefaultLayoutPod1").scrollTo(0, 0);
      }
    });


    [donate].forEach((link)=>{
      link.click = ()=>this.utilService.getWindow().open("https://gofund.me/bf0b6977")
    });

    return quickLinkParams
  })()

  languageParams = new SectionZeroParams({
    title:"FooterZero.languages.title",
    items:enTranslations.FooterZero.languages.items.map((item,index0)=>{
      return new WMLRoute({
        text:"FooterZero.languages.items."+index0,
        click:()=>{
          let lang = [
            "en","zh","es","hi",
            "uk","ar","bn","ms","fr",
            "de","sw","am"
          ][index0]
          this.utilService.changeLanguage(lang)
        }
      })
    })
  })


  legalParams = new SectionZeroParams({
    title: "FooterZero.legal.title",
    items: enTranslations.FooterZero.legal.items.map((item, index0) => {
      return new WMLRoute({
        text: "FooterZero.legal.items." + index0,

        click: () => {
            let route = ["privacyPolicy","termsAndConditions"][index0]
            this.utilService.router.navigateByUrl(ENV.nav.urls[route])
            documentQuerySelector(".DefaultLayoutPod1").scrollTo(0, 0);
        }
      });
    }),
  });

  socialMediaImgs = Array(12)
  .fill(null)
  .map((nullVal,index0)=>{
    return new WMLImage({
      src:"assets/media/footer/footer_"+index0+".png",
      alt:"FooterZero.socialMedia.socialIconsAlt."+index0,
      click:()=>{
        this.utilService.getWindow().location.href = [
          "https://theblacktube.com/@WindMillCode",
          "https://www.facebook.com/Windmillcode-100338862024871/",
          "https://twitter.com/windmillcode",
          "https://www.instagram.com/WindMillCode/",
          "https://patreon.com/WindMillCode",
          "https://www.tiktok.com/@windmillcode?lang=en",
          "https://discord.com/invite/uegqxpsDrg",
          "https://app.slack.com/workspace-signin?redir=%2Fgantry%2Fauth%3Fapp%3Dclient%26lc%3D1628494381%26return_to%3D%252Fclient%252FT01KAH3L82E%252FC01K3PRF5K8%26teams%3DT02ASQWG396%252CT02AMB34T1P%252CT0B0LG60J",
          "https://www.pinterest.com/Windmillcode/_saved/",
          "https://windmillcode.tumblr.com/",
          "https://www.reddit.com/user/windmillcode",
          "https://www.youtube.com/@windmillcode9189"
        ][index0]
      }
    })
  })


  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}




