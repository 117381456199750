
import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel } from "@windmillcode/angular-wml-components-base"
import { Observable, of, take } from "rxjs"

export let listUsersLoad = (uiBody:ListUsersUIRequestBody): Observable<ListUsersAPIRequestBody>=>{

  let apiBody = new ListUsersAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  apiBody.data.page_size =1
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listUsersSuccess = (apiBody:ListUsersAPIResponseBody):ListUsersUIResponseBody=>{
  let uiBody = new ListUsersUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  uiBody.data =uiBody.data.map((user)=>{
    user.projects = user.projects.map((project)=>{
      project = new  ListUsersUIResponseProjectBody(project)
      return project
    })
    return user
  })
  return uiBody
}


export enum ListUsersUIRequestBodyTypeEnum {
  CREATEACCTIFNOTEXISTS
}
export class ListUsersUIRequestBody extends WMLAPIPaginationRequestModel {
  constructor(params:Partial<ListUsersUIRequestBody>={}){
    super(params)
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  email?:string
  accessToken:string
  type:ListUsersUIRequestBodyTypeEnum
}

export class ListUsersUIResponseBody extends WMLAPIPaginationResponseModel {
  constructor(params:Partial<ListUsersUIResponseBody>={}){
    super(params)
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  override data:{
    emailNotificationsIsPresent:boolean
    smsNotificationsIsPresent:boolean
    addresses?:{
      city: string;
      country: string;
      secondaryAdr: string;
      state: string;
      streetAdr: string;
      zipcode: string;
    }
    projects:Array<ListUsersUIResponseProjectBody>
  }[]

}

export class ListUsersUIResponseProjectBody {
  constructor(params: Partial<ListUsersUIResponseProjectBody> = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  creationDate:Date
  get displayCreationDate(){
    return new Date(this.creationDate).toLocaleDateString()
  }
  name:string
  type:"Volunteer" | "Business"
  url:string
}

export class ListUsersAPIRequestBody {
  constructor(params:Partial<ListUsersAPIRequestBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  data:RecursiveSnakeCaseType<ListUsersUIRequestBody>

}

export class ListUsersAPIResponseBody {
  constructor(params:Partial<ListUsersAPIResponseBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  data:RecursiveSnakeCaseType<ListUsersUIResponseBody>
}
