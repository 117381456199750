import { transformObjectKeys } from "@core/utility/common-utils"
import { transformFromCamelCaseToSnakeCase } from "@core/utility/string-utils"
import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel } from "@windmillcode/angular-wml-components-base"
import { WmlTableZeroItemParams } from "@windmillcode/angular-wml-table-zero"
import { defer, Observable, take } from "rxjs"
import { AccountsServiceUser } from "../accounts/accounts.service"
import { WMLDataSourceSinkResponseBody } from "@core/utility/data-source-utils"

let navigatorObs$ = (apiBody:ListProductsAPIRequestBody)=> defer(
  ()=>new Promise((res,rej)=>{
    navigator.geolocation.getCurrentPosition(
      (position)=>{
        apiBody.data.ai_data.location = {
          lat:position.coords.latitude,
          lng:position.coords.longitude
          // lat:generateRandomNumber(),
          // lng:generateRandomNumber()
        }
        res(apiBody)
      },
      ()=>{
        res(apiBody)
      }
    )
  })
)
export let listProductsLoad = (
  uiBody:ListProductsUIRequestBody,
  visitedLinks:Array<string>,
  darkMode:boolean,
  useAiPricing:boolean,
  currentUser?:AccountsServiceUser
): Observable<ListProductsAPIRequestBody>=>{


  let aiData = transformObjectKeys({
    visitedLinks,
    darkMode,
    useAiPricing
  },transformFromCamelCaseToSnakeCase)
  if(currentUser){
    let {user}= currentUser
    Object.assign(
      aiData,
      transformObjectKeys({
        accountCreated:user.metadata.creationTime,
        lastSignInTime:user.metadata.lastSignInTime,
        isAnonymous:user.isAnonymous,
        name:user.displayName,
        email:user.email,
        emailVerified:user.emailVerified

      },transformFromCamelCaseToSnakeCase)
    )
  }
  let apiBody = new ListProductsAPIRequestBody(transformObjectKeys({
    data:{
      pageData:Object.assign({},uiBody),
      aiData
    }
  },transformFromCamelCaseToSnakeCase))
  // @ts-ignore
  apiBody.data.page_data.page_num = parseInt(apiBody.data.page_data.page_num)
  // @ts-ignore
  apiBody.data.page_data.page_size = parseInt(apiBody.data.page_data.page_size)
  // @ts-ignore
  return navigatorObs$(apiBody)
  .pipe(
    take(1),
  )

}

export class ListProductsUIRequestBody extends WMLAPIPaginationRequestModel {
  constructor(params:Partial<ListProductsUIRequestBody>={}){
    super();
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  aiMetadata?:{
    [k:string]:any
  }
}

export class ListProductsUIResponseBody extends WMLAPIPaginationResponseModel<WmlTableZeroItemParams> {
  constructor(params:Partial<ListProductsUIResponseBody>={}){
    super();
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
}


export class ListProductsAPIRequestBody {
  constructor(params:Partial<ListProductsAPIRequestBody>={}){
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  data:{
    page_data:{
      filter:WMLAPIPaginationRequestModel["filter"]
      sort:WMLAPIPaginationRequestModel["sort"]
      page_num:WMLAPIPaginationRequestModel["pageNum"] | number
      page_size:WMLAPIPaginationRequestModel["pageSize"] | number
    },
    ai_data:{
      visited_links?:Array<string>,
      dark_mode?:boolean,
      location?:any,
      use_ai_pricing:boolean
    }
  }
}

export class ListProductsAPIResponseBody extends WMLDataSourceSinkResponseBody {
  constructor(params: Partial<ListProductsAPIResponseBody> = {}) {
    super(params)
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }

}
