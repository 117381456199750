<div [class]="classPrefix('MainPod')">

  <section *ngIf="params.optionsContainer.isPresent" [class]="classPrefix('Pod0')">
    <ng-container *ngFor="let card of params.cards; let i = index">
      <label
      [ngStyle]="card.option.style"
      (click)="updateCurrentCard(card,'option')" [for]="i">{{ i + 1 }}</label>
    </ng-container>
  </section>


  <section [class]="classPrefix('Pod1')">
    <div [ngStyle]="params.carousel.style" [class]="classPrefix('Pod1Item0')">


      <div
      [class]="classPrefix('Pod1Item1')"
      [ngStyle]="card.card.style"
      (click)="updateCurrentCard(card,'card')"
      *ngFor="let card of params.cards; let i = index"
      >
        <ng-template #customCards></ng-template>
      </div>
    </div>

  </section>

  <section [class]="classPrefix('Pod2')">

    <div
    *ngIf="params.chosenCard"
    [class]="classPrefix('Pod2Item0')"
    >
      <ng-template
        #chosenCard
      ></ng-template>
    </div>
  </section>
</div>
