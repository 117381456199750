import { traverseClassAndRemoveUpdateAutomation } from "@core/utility/env-utils"
import { DevEnv } from "./environment.dev"


export let environment = {
  production: true
}
class PreviewEnv extends DevEnv  {


  constructor(){
    super()
    this.app.freeTrialEndsAfter = 5
    this.type = "PREVIEW"
    this.backendServerName0 = "api.preview.windmillcode.com"
    // silenceAllConsoleLogs()
    traverseClassAndRemoveUpdateAutomation(this)
    this.firebase.storageImageURL ="https://firebasestorage.googleapis.com/v0/b/windmillcodesite.appspot.com/o/"
    this.firebase.config ={
      ...this.firebase.config,
      authDomain: "windmillcode.com",
    }
  }
}


export let ENV =   new PreviewEnv()
