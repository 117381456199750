import { ScolllPaginationParams } from "@core/base/base.service"
import { transformObjectKeys, transformPropertiesOnObject } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { CardZeroParams } from "@shared/components/card-zero/card-zero.component"
import { LabViewerZeroParams } from "@shared/components/lab-viewer-zero/lab-viewer-zero.component"
import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel, WMLImage } from "@windmillcode/angular-wml-components-base"
import { Observable, of, take } from "rxjs"

export let listLabsLoad = (uiBody:ListLabsUIRequestBody): Observable<ListLabsAPIRequestBody>=>{



  let apiBody = new ListLabsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listLabsSuccess =(scrollPaginationObj:ScolllPaginationParams,uiRequestBody:ListLabsUIRequestBody)=> (apiBody:ListLabsAPIResponseBody):ListLabsUIResponseBody=>{

  uiRequestBody.errorOccuredIsPresent = false
  let transformTarget = apiBody.data
  transformTarget.data = transformTarget.data.map((apiLab)=>{
    let uiLab = transformPropertiesOnObject(apiLab,transformFromSnakeCaseToCamelCase)

    let card = new CardZeroParams({
      ...uiLab,
      type:"lab",
      paramSummary:"",
      imgUrl:new WMLImage({
        src:uiLab.thumbnailUrl ?? "assets/media/app/logo.png",
        alt:"EntityMain.labs.imgAlt"
      })
    })
    let videoUrl = "https://www.youtube.com/embed/"+ uiLab.videoUrl.split("watch?v=")[1]
    card.labViewer = new LabViewerZeroParams({
      videoUrl,
      readmeUrl:uiLab.readmeUrl,
      title:uiLab.title
    })
    return card
  })
  // @ts-ignore
  let uiBody = new ListLabsUIResponseBody(transformTarget)
  if((uiBody.pageNum ===uiBody.totalPages-1 || uiBody.totalItems ===0 )&& scrollPaginationObj ){
    scrollPaginationObj.stopMakingAPICalls = true
  }
  return uiBody
}

export let listLabsFail = (uiBody:ListLabsUIRequestBody)=>{
  if(!uiBody.errorOccuredIsPresent){
    uiBody.errorOccuredIsPresent = true
    uiBody.pageNum = uiBody.pageNum === 0 ? 0 : uiBody.pageNum -1
  }
}
export class ListLabsUIRequestBody extends WMLAPIPaginationRequestModel {
  constructor(params:Partial<ListLabsUIRequestBody>={}){
    super()
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
}

export class ListLabsAPIRequestBody {
  constructor(params:Partial<ListLabsAPIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<ListLabsUIRequestBody>
}

export class ListLabsUIResponseBody  extends WMLAPIPaginationResponseModel {
  constructor(params:Partial<ListLabsUIResponseBody>={}){
    super();
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  override data: CardZeroParams[];
}

export class ListLabsAPIResponseBody {
  constructor(params:Partial<ListLabsAPIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:WMLAPIPaginationResponseModel<ListLabsLab>
}

export class ListLabsLab {
  constructor(params: Partial<ListLabsLab> = {}) {
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  id:string
  readmeUrl?:string
  thumbnailUrl?:string
  title:string
  videoUrl?:string
}
