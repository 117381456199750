import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let getCardServerSessionIdLoad = (uiBody:GetCardServerSessionIdUIRequestBody): Observable<GetCardServerSessionIdAPIRequestBody>=>{


  let apiBody = new GetCardServerSessionIdAPIRequestBody({
    credit_card:{
      number:uiBody.cardNumber,
      month:uiBody.expiryDate.month,
      year:"20"+uiBody.expiryDate.year,
      verification_value:uiBody.securityCode
    }
  })
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let getCardServerSessionIdSuccess = (apiBody:GetCardServerSessionIdAPIResponseBody):GetCardServerSessionIdUIResponseBody=>{
  let uiBody = new GetCardServerSessionIdUIResponseBody(transformObjectKeys(
    apiBody,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class GetCardServerSessionIdUIRequestBody {
  constructor(params:Partial<GetCardServerSessionIdUIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  cardNumber: string
  expiryDate: {
    month:  string,
    year: string
  }
  securityCode: string

}

export class GetCardServerSessionIdAPIRequestBody {
  constructor(params:Partial<GetCardServerSessionIdAPIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  credit_card:{
    number: string;
    first_name?: string;
    last_name?: string;
    month: string;
    year: string;
    verification_value: string;
  }
}

export class GetCardServerSessionIdUIResponseBody {
  constructor(params:Partial<GetCardServerSessionIdUIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  id:string
}

export class GetCardServerSessionIdAPIResponseBody {
  constructor(params:Partial<RecursiveSnakeCaseType<GetCardServerSessionIdUIResponseBody>>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
}

