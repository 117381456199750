export class WMLError extends Error {
  constructor(params:Partial<WMLError>={}){
    super(params.message)
    Object.assign(
      this,
      {
        ...params
      }
    )
  }

  openSystemErrorBanner: "false"|"true"= "true"
}


export class CantReachBackendError extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthEmailAlreadyInUse extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}


export class AuthUserNotFound extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthWrongPassword extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthInvalidPasswordResetCode extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class UserCanceledAuthError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}


export class APIServerError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class ServiceUnavailableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}



// dev additions
export class SquareTokenizeError extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}


