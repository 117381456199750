import { FormGroup } from "@angular/forms";

export let checkForExpiryMonthAndYear = (parent:FormGroup,monthField="month",yearField="year")=>{
  let monthRegex = /^(0[1-9]|1[0-2])$/;
  let yearRegex = /^\d{2}$/
  if(!yearRegex.test(parent.controls[yearField].value) && !monthRegex.test(parent.controls[monthField].value)){
    return {
      required:true
    }
  }
  if(!monthRegex.test(parent.controls[monthField].value)){
    return {
      invalidMonth:true
    }
  }
  if(!yearRegex.test(parent.controls[yearField].value)){
    return {
      invalidYear:true
    }
  }

  return null
}
