import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let updateUserLoad = (uiBody:UpdateUserUIRequestBody): Observable<UpdateUserAPIRequestBody>=>{


  let apiBody = new UpdateUserAPIRequestBody({data:transformObjectKeys(
    uiBody,

    transformFromCamelCaseToSnakeCase
  )})

  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let updateUserSuccess = (apiBody:UpdateUserAPIResponseBody):UpdateUserUIResponseBody=>{
  let uiBody = new UpdateUserUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class UpdateUserUIRequestBody {
  constructor(params:Partial<UpdateUserUIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  accessToken:string
  users:Array<Partial<{
    photoUrl:{
      content:string,
      mimeType:string
    }
    id:string
    displayName:string
    email:string
    phoneNumber:string
    emailMarketingConsent:boolean
    smsMarketingConsent:boolean
  }>>
}

export class UpdateUserAPIRequestBody {
  constructor(params:Partial<UpdateUserAPIRequestBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<UpdateUserUIRequestBody&{photoUrl:string}>
}

export class UpdateUserUIResponseBody {
  constructor(params:Partial<UpdateUserUIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }

}

export class UpdateUserAPIResponseBody {
  constructor(params:Partial<UpdateUserAPIResponseBody>={}){
    let origParams = Object.entries(params).filter(([key, val]) => {
      return !key.startsWith('param');
    });
    Object.assign(this, { ...Object.fromEntries(origParams) });
  }
  data:RecursiveSnakeCaseType<UpdateUserUIResponseBody>
}

