// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/angular-wml-components-base';

// misc
import { ENV } from '@env/environment';



@Component({

  selector: 'section-two',
  templateUrl: './section-two.component.html',
  styleUrls: ['./section-two.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class SectionTwoComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService

  ) { }

  classPrefix = generateClassPrefix('SectionTwo')
  idPrefix = generateClassPrefix(ENV.idPrefix.sectionTwo)
  @Input('params') params: SectionTwoParams = new SectionTwoParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()

  ngUnsub= new Subject<void>()

  ngOnInit(): void {

  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class SectionTwoParams {
  constructor(params:Partial<SectionTwoParams &{paramRows:any[][][]}>={}){
    Object.assign(
      this,
      {
        ...params,
      }
    )
    this.init(params)

  }
  tableDesc = "SectionTwo.tableDesc"
  rows: SectionTwoSectionParams<WMLUIProperty[]>[][] =[]
  get displayRows (){
    let newRows =this.rows[0].map((row)=>{
      return [row]
    })
    this.rows
    .slice(1,this.rows.length)
    .map((section)=>{
      section.forEach((row,index0)=>{
        newRows[index0].push(row)
      })
    })

    return newRows
  }

  init=(params)=>{
    this.rows = []
    this.rows.unshift(["featureTitle","detailsTitle","turnarondTitle","priceTitle"]
    .map((i18nString)=>{
      return       new SectionTwoSectionParams({
        value:[
          new WMLUIProperty({
            text:"SectionTwo." + i18nString,
            style:{
              textAlign:"center",
              fontSize:"var(--display)",
              fontWeight:"700"
            }
          }),
        ]
      })
    }))
    if(params.paramRows){
      params.paramRows.forEach((doubleWrapI18nString)=>{
        let row = doubleWrapI18nString.map((signleWrapI18nString)=>{
          return new SectionTwoSectionParams<WMLUIProperty[]>({
            value:signleWrapI18nString.map((i18nString)=>{
              if(i18nString instanceof WMLUIProperty){
                return i18nString;
              }
              return new WMLUIProperty({
                text:i18nString,
              })
            })
          })
        })
        this.rows.push(row)

      })
    }
    else{
      this.rows.push(...params.rows ?? [])
    }
  }
}

export class SectionTwoSectionParams<V,T=any> extends WMLUIProperty<V,T> {
  constructor(params:Partial<SectionTwoSectionParams<V,T>>={}){
    super(params);
    Object.assign(
      this,
      {
        ...params
      }
    )
  }
  rowspan = 1
}
