import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import { fromEvent } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService
  ) { }

  client = io(ENV.backendDomain0)

  connectEvent = fromEvent(this.client, 'connect');
  checkForAuthenticationEvent = fromEvent(this.client,"check_for_authentication")
}
